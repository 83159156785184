// stores/cacheStore.js
import { defineStore } from 'pinia'
 
export const useCacheStore = defineStore({
  id: 'cache',
  state: () => ({
    cache: {}
  }),
  actions: {
    set(key, value) {
      this.cache[key] = value;
    },
    get(key) {
      return this.cache[key];
    }
  }
});