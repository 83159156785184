// 首先引入axios和上一步封装的cookie方法
import axios from "axios";

export class Interceptors {
  constructor() {
    this.instance = axios.create({
      // baseURL: process.env.BROWSERSLIST,
      baseURL: "/",
      timeout: 60000,
      withCredentials: true,
      headers: {
        "X-Requested-With": "X-Requested-With",
        token: localStorage.getItem("token") || "",
        "Content-Type": "application/json",
      },
    });
    this.init();
  }
  // 初始化拦截器
  init() {
    // 请求接口拦截器
    this.instance.interceptors.request.use(
      (config) => {
        // 每次请求携带toke

        let toke = window.sessionStorage.getItem("token");
        if (toke) {
          config.headers.Authorization = toke;
        }
        return config;
      },
      (err) => {
        return Promise.resolve(err);
      }
    );

    // 响应拦截器
    this.instance.interceptors.response.use(
      //判断当前请求是否设置了不显示Loading（不显示自然无需隐藏）
      (config) => {
        return Promise.resolve(config.data);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }
  // 返回一下
  getInterceptors() {
    return this.instance;
  }
}
